import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const generateMonthCategories = (series) => {
    const categories = [];
    let year = 22;
    let month = 1;
    const dataLength = series.length > 0 ? series[0].data.length : 0;
    for (let i = 0; i < dataLength; i++) {
        categories.push(`${year}.${month.toString().padStart(2, '0')}`);
        month++;
        if (month > 12) {
            month = 1;
            year++;
        }
    }
    return categories;
};

export const Statistics1 = () => {
    const [chartData, setChartData] = useState({
        series: [],
        options: {
            chart: {
                height: 30,
                type: 'line',
                zoom: {
                    enabled: false
                },
                animations: {
                    enabled: true,
                    easing: 'easeout',
                    speed: 800,
                    animateGradually: {
                        enabled: true,
                        delay: 150
                    },
                    dynamicAnimation: {
                        enabled: true,
                        speed: 350
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            markers: {
                size: 0.1,
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'],
                    opacity: 0.5
                },
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + "%"
                    }
                }
            },
            xaxis: {
                labels: {
                    style: {
                        fontSize: '8px'
                    }
                }
            },
            yaxis: {
                max: 28,
                min: -2,
                labels: {
                    show: false
                }
            },
            legend: {
                show: false,
                position: 'bottom',
                maxHeight: 325 ,
                fontSize:'15px',
                fontFamily: 'Pretendard-Regular',
                itemMargin: {
                    vertical: 40
                },

            },
        }
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchChartData();
                console.log(data)
                setChartData(prevState => ({
                    ...prevState,
                    series: data,
                    options: {
                        ...prevState.options,
                        xaxis: { categories: generateMonthCategories(data) }
                    }
                }));
            } catch (error) {
                console.error("Error fetching chart data:", error);
            }
        };
        fetchData();
    }, []);

    const fetchChartData = () => {
        return new Promise(resolve => {
            fetch('https://raw.githubusercontent.com/bookspider/nghp_content/main/content/chart.json')
                .then(response => response.json())
                .then(data => {
                    const filteredData = [...data.slice(0, 9)];
                    resolve(filteredData);
                })
                .catch(error => console.error("Error fetching chart data:", error));
        });
    };


    return (
        <div className="flex ">
            <span className=" absolute
                              lg:w-[400px] lg:pt-[320px] lg:pr-[20px] lg:text-[16px] lg:pl-[120px]
                              md:w-[190px] md:pt-[365px] md:pr-[70px] md:text-[13px] md:pl-[24px]
                              sm:w-[190px] sm:pt-[285px] sm:pr-[90px] sm:text-[10px] sm:pl-[16px]">
                   <div className="">   <span className="inline-block w-[10px] h-[10px] rounded-[50%] bg-[#007CF7] "></span>  교보문고   </div>
                                <div>    <span className="inline-block w-[10px] h-[10px] rounded-[50%] bg-[#6DB329] "></span> 예스24    </div>
                                <div>    <span className="inline-block w-[10px] h-[10px] rounded-[50%] bg-[#9181cd] "></span> 알라딘      </div>
                                <div>    <span className="inline-block w-[10px] h-[10px] rounded-[50%] bg-[#ffb280] "></span> 북센      </div>
                                <div>    <span className="inline-block w-[10px] h-[10px] rounded-[50%] bg-[#F93D48] "></span> 쿠팡      </div>
                                <div>   <span className="inline-block w-[10px] h-[10px] rounded-[50%] bg-[#996C33] "></span>  영풍문고     </div>
                                <div>   <span className="inline-block w-[10px] h-[10px] rounded-[50%] bg-[#e383a3] "></span>  북플러스    </div>
                                <div>  <span className="inline-block w-[10px] h-[10px] rounded-[50%] bg-[#F7E900] "></span>   출판협동조합  </div>
                                <div>   <span className="inline-block w-[10px] h-[10px] rounded-[50%] bg-[#97eaf4] "></span>  세원도서    </div>
                                <div>  <span className="inline-block w-[10px] h-[10px] rounded-[50%] bg-[#06c2f4] "></span>   북플레이스   </div>
            </span>
            <span id="chart" className="lg:pl-[220px] lg:pr-[120px] lg:py-[30px] w-full
                                        md:pl-[105px] md:pr-[24px] md:py-[30px]
                                        sm:pl-[65px]  sm:pr-[8px] sm:py-[20px]">
                <ReactApexChart options={chartData.options} series={chartData.series} type="line" height={600}/>
            </span>
        </div>
    );
};
