import React from 'react';
import {Link} from 'react-router-dom';
import truck2 from "../../../images/home/web/truck2.svg";

const Home7 = () => {
    return (
        <>
            <div className="bg-[#2F4FB7] gap-5 overflow-x-hidden
                            lg:min-h-[360px] lg:grid-cols-2 lg:grid
                            md:min-h-[300px] md:grid-cols-2 md:grid
                            sm:min-h-[200px] sm:max-h-[450px] sm:grid-cols-1 sm:grid sm:min-w-[320px] ">
                <div className="text-gray-700
                                lg:mt-[84px] lg:ml-[120px] lg:h-[80px]
                                md:mt-[84px] md:ml-[80px]  md:h-[80px]
                                sm:mt-[80px] sm:mx-[10px]  sm:h-[120px] ">
                    <div className="text-justify">
                        <h4 className="text-white font-[500] h-auto
                                      lg:text-[36px] lg:leading-[52px] lg:w-[700px] lg:text-left
                                      md:text-[22px] md:leading-[42px] md:w-[500px] md:text-left
                                      sm:text-[16px] sm:leading-[32px] sm:w-[400px] sm:text-center sm:m-auto ">입고 &gt; 보관 &gt; 출고 및 반품관리 &gt; 전국배송까지<br/>최고의 출판물류 서비스를 받아보세요
                        </h4>
                        <div className="sm:flex lg:justify-center md:justify-center">
                            <Link to="/qna" className="text-[#2F4FB7] bg-[#F9BA5D] inline-flex px-[33.5px] py-[14px] rounded-[8px] border-solid text-[16px] leading-[20px]
                                                       lg:mt-[50px]
                                                       md:mt-[30px]
                                                       sm:mt-[30px] sm:justify-center sm:flex  sm:m-auto sm:mt-[36px]">문의하기
                            </Link>
                        </div>
                    </div>
                </div>

                <div
                    className="transition-all ease-in-out duration-400  text-gray-700 rounded-lg sm:m-auto
                    ">
                    <img alt="card img"    src={truck2}  className=" overflow-x-hidden
                                                                     lg:ml-[200px] lg:my-[80px]  lg:h-[230px]
                                                                     md:ml-[80px]  md:mt-[105px]  md:h-[200px]
                                                                     sm:ml-[100px] sm:my-[41px]  sm:h-[140px] "/>
                </div>
            </div>
        </>
    )
}

export default Home7;